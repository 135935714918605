<template>
  <div>
    <!-- #region Mobile Filters -->
    <v-col v-if="$vuetify.breakpoint.smAndDown">
      <v-dialog v-model="dialog" scrollable fullscreen>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="orange"
            class="white--text py-2"
            width="100%"
            height="100%"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon large>mdi-filter</v-icon>
            Фильтры
          </v-btn>
        </template>
        <v-card tile>
          <v-card-title class="orange py-5">
            <div style="color: white">Фильтры</div>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              fab
              elevation="0"
              outlined
              x-small
              @click="dialog = false"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <slot></slot>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#666666"
              elevation="0"
              normal
              rounded
              outlined
              class="px-5"
              @click="$emit('drop-filters')"
            >
              Сбросить
            </v-btn>
            <v-btn
              color="orange"
              elevation="0"
              normal
              rounded
              @click="dialog = false"
              class="px-5"
            >
              <div style="color: white">Готово</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <!-- #endregion -->
    <!-- #region Desktop Filters -->
    <v-col class="d-none d-md-block">
      <v-row no-gutters>
        <v-col cols="10" class="pt-0 pt-md-1">
          <div class="text-h5 text-lg-h4">Фильтры</div>
        </v-col>
        <v-col cols="2" class="pb-0 pb-md-2">
          <v-tooltip top color="blue-grey lighten-2" open-delay="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#666666"
                elevation="0"
                fab
                x-small
                rounded
                outlined
                class="mt-1"
                v-bind="attrs"
                v-on="on"
                @click="$emit('drop-filters')"
              >
                <v-icon> mdi-broom </v-icon>
              </v-btn>
            </template>
            <span>Сбросить фильтры</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-container>
        <v-row>
          <v-col>
            <slot></slot>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <!-- #endregion -->
  </div>
</template>

<script>
export default {
  name: "TicketSearchFiltersList",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style></style>
