import Passenger from "@/Models/Passenger";
import RailwayPassenger from "@/Models/RailwayPassenger";
export default {
  methods: {
    getPassengersData(adults, kids, bbs) {
      return [
        {
          Code: "ААА",
          Count: adults,
          Age: 30,
        },
        {
          Code: "РБГ",
          Count: kids,
          Age: 5,
        },
        {
          Code: "РМГ",
          Count: bbs,
          Age: 0,
        },
      ];
    },
    _model(category) {
      return {
        Name: "Naruto",
        Surname: "Uzumaki",
        Dob: "30.01.2001",
        Birthdate: "30.01.2001",
        Sex: "male",
        Category: category,
        Doccode: "ПС",
        Doc: "4521129324",
        DocCountry: "RU",
        Nationality: "RU",
        Residence: "RU",
      };
    },
    generateModels(adt, cnn, inf) {
      let isTest = process.env.NODE_ENV === "development";
      let models = [];
      for (let i = 0; i < Number(adt); i++) {
        models.push(new Passenger({ test: isTest, Category: "ADT" }));
      }
      for (let i = 0; i < Number(cnn); i++) {
        models.push(new Passenger({ test: isTest, Category: "CNN" }));
      }
      for (let i = 0; i < Number(inf); i++) {
        models.push(new Passenger({ test: isTest, Category: "INF" }));
      }

      console.error(models);
      return models;
    },
    railwayGenerateModels() {
      let isTest = false;
      let models = [];
      for (let i = 0; i < this.adults; i++) {
        models.push(new RailwayPassenger({ test: isTest, Category: "Adult" }));
        //models.push(this._model("ААА"));
      }
      for (let i = 0; i < this.kids; i++) {
        models.push(new RailwayPassenger({ test: isTest, Category: "Child" }));
        //models.push(this._model("РБГ"));
      }
      for (let i = 0; i < this.bbs; i++) {
        models.push(
          new RailwayPassenger({ test: isTest, Category: "BabyWithoutPlace" })
        );
        //models.push(this._model("РМГ"));
      }
      return models;
    },
  },
};
