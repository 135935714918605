export default class Passenger {
    FirstName = "";
    LastName = "";
    MiddleName = "";
    Birthdate = "";
    Sex = "";
    Category = {
      Text: "",
      Rbm: null,
    };
    DocType = "";
    Doc = "";
    Nationality = "";
    Pspexpire = null;
    Car = null
    CarType = null;

    constructor(options) {
      let test = options.test || false;
      if (test) {
        this.FirstName = "Георгий";
        this.LastName = "Макаров";
        this.MiddleName = "Владимирович";
        this.Birthdate = "06.05.2002";
        this.Sex = "Male";
        this.Category.Text = options.Category || "Adult";
        this.DocType = "RussianPassport";
        this.Doc = "4522911295";
        this.Nationality = "RU";
        this.Pspexpire = null;
      } else {
        this.FirstName = "";
        this.LastName = "";
        this.MiddleName = "";
        this.Birthdate = "";
        this.Sex = "Male";
        this.Category.Text = options.Category || "Adult";
        this.DocType = "RussianPassport";
        this.Doc = "";
        this.Nationality = "RU";
        this.Pspexpire = null;
      }
    }
  
    Validate() {
      return (
        this.Name &&
        this.Surname &&
        this.Dob &&
        this.Birthdate &&
        this.Sex &&
        this.Category &&
        this.Doccode &&
        this.Doc &&
        this.DocCountry &&
        this.Nationality &&
        this.Residence
      );
    }
  }
  