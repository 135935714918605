<template>
  <div>
    <v-row align="center" justify="space-between" class="mb-0">
      <div class="text-h6 my-3">
        {{ title }}
      </div>
      <v-tooltip top color="gray" open-delay="400">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="invert" v-bind="attrs" v-on="on">
            <v-icon> mdi-swap-horizontal </v-icon>
          </v-btn>
        </template>
        <span>Инвертировать выбранное</span>
      </v-tooltip>
    </v-row>

    <v-responsive max-height="100%">
      <v-checkbox
        v-for="(item, i) in base"
        :key="i"
        color="orange"
        v-model="current"
        :label="item.text"
        :value="returnObject ? item : item.value"
        class="my-0"
      >
      </v-checkbox>
    </v-responsive>
  </div>
</template>

<script>
export default {
  name: "CheckBoxFilter",
  props: {
    title: {
      type: String,
      require: true,
    },
    base: {
      type: Array,
      require: true,
    },
    array: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "array",
    event: "change-array",
  },
  data() {
    return {
      current: [],
    };
  },
  watch: {
    current(arr) {
      this.$emit("change-array", arr);
    },
  },
  methods: {
    invert() {
      let temp = [...this.current];
      this.current.splice(0, this.current.length);
      this.base
        .filter((e) => !temp.includes(this.returnObject ? e : e.value))
        .forEach((element) => {
          this.current.push(this.returnObject ? element : element.value);
        });
    },
  },
};
</script>

<style></style>
